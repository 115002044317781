import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import styled, { AnyStyledComponent, useTheme } from "styled-components";
import { imageFitDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import GoBackButton from "app/components/common/GoBackButton";
import { createImageFitLink } from "app/services/filestackClient";
import { Dimensions, FitOptions, MediaCategory, PatchOperation } from "app/types";
import { scenesActions } from "app/store/slices/scenes.slice";
import { FilterType } from "app/types/media";
import { RootState } from "app/store/store";
import ImageFitType from "app/components/editor/sideDrawers/SceneDrawer/ImageFit/ImageFitType";
import ImageFitFilterType from "app/components/editor/sideDrawers/SceneDrawer/ImageFit/ImageFitFilterType";
import ReactCrop, { Crop } from "react-image-crop";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import { debounce } from "lodash-es";
import { fileStackFetchImageSizePromise } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const ReactCropFlexColumn = styled(H1_FlexColumn)`
  align-self: center;
  opacity: ${(props: { $opacity: boolean; $background?: string }) => (props.$opacity ? 1 : 0)};
  z-index: ${(props: { $opacity: boolean }) => (props.$opacity ? 3 : -1)};
  background-color: ${(props: { $background?: string }) =>
    props.$background ? props.$background : ""};
`;

const StyledReactCrop = styled(ReactCrop as unknown as AnyStyledComponent)`
  align-self: center;
  height: ${(props: { aspect: number; $height: string }) =>
    props.aspect > 1 ? "auto" : props.$height};
  width: ${(props: { aspect: number; $width: string }) =>
    props.aspect > 1 ? props.$width : "auto"};
`;

const FixedImage = styled.img`
  && {
    max-height: 186px;
    max-width: calc(186px * 16 / 9);
  }
  aspect-ratio: ${(props: { $aspect: number }) => props.$aspect || 16 / 9};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    display: flex;
    flex: 0 0 auto;
    object-fit: contain;
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
`;

const LinkImage = styled(FixedImage)`
  position: absolute;
  display: flex;
  align-self: center;
  justify-content: center;
  opacity: ${(props: { $opacity: boolean; $aspect: number }) => (props.$opacity ? 1 : 0)};
  z-index: ${(props: { $opacity: boolean; $aspect: number }) => (props.$opacity ? 10 : -1)};
  aspect-ratio: ${(props: { $opacity: boolean; $aspect: number }) => props.$aspect};
`;

const StyledFlexDrawer = styled(FlexDrawer)`
  padding: ${({ active }) => (active ? "20px 24px" : "0")};
`;

const ImageLoadingWrapper = styled(H1_FlexColumn)`
  z-index: 12;
`;
export interface ImageFitDrawerProps {
  right: string;
  width: string;
}

const ImageFitDrawer = ({ right, width }: ImageFitDrawerProps) => {
  const [linkImageLoading, setLinkImageLoading] = useState<boolean>(false);
  const [previewWidth, setPreviewWidth] = useState<number>(0);
  const [previewHeight, setPreviewHeight] = useState<number>(0);
  const [onLoadingImage, setOnLoadingImage] = useState<boolean>(true);
  const [originalImageWidth, setOriginalImageWidth] = useState<number>(0);
  const [originalImageHeight, setOriginalImageHeight] = useState<number>(0);
  const [crop, setCrop] = useState<Crop>();
  const imageRef = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { editorDrawerOpen, originContext, openDrawer } = useDrawer();
  const { scene } = useSelectedScene();
  const theme = useTheme();

  const { backDrawer, media, attributeType } = originContext;
  const mediaAttributeType = attributeType as MediaCategory;

  const assetKey = media?.key || "";
  const draft = useAppSelector((state: RootState) => state.drafts.currentDraft);
  const filestackPolicy = useAppSelector((state: RootState) => state.media.filestackReadPolicy);
  const imageFit = useAppSelector((state: RootState) => state.scenes.imageFit);
  const currentValue =
    mediaAttributeType === "visual"
      ? scene?.attributes?.visual?.[assetKey]?.preset_override?.media_url || ""
      : scene?.attributes?.media?.[assetKey]?.url || "";
  const modification = scene?.attributes?.[mediaAttributeType]?.[assetKey]?.modification;
  const resolvedPreviewUrl = currentValue;
  const { displayUrls } = useDisplayUrls([resolvedPreviewUrl]);
  const resolvedUrl = currentValue;
  const isDrawerActive = editorDrawerOpen === Drawer.ImageFit;
  const isCropVisible =
    imageFit.fitType === FitOptions.crop && !onLoadingImage && !!originalImageWidth;
  const isLinkImageVisible = imageFit.fitType !== FitOptions.crop && !linkImageLoading;

  const currentLayoutMedia = useMemo(
    () =>
      scene?.layout?.assets?.[mediaAttributeType]?.find((theMedia) => theMedia.key === assetKey),
    [mediaAttributeType, assetKey, scene?.id]
  );
  const fitImageWidth = useMemo(() => {
    const ratioWidth = currentLayoutMedia?.restrictions?.ratio_w || 1;
    const ratioHeight = currentLayoutMedia?.restrictions?.ratio_h || 1;
    if (originalImageWidth && originalImageHeight && ratioWidth && ratioHeight) {
      const aspectRatio = ratioWidth / ratioHeight;
      if (aspectRatio === 1) {
        return Math.min(originalImageWidth, originalImageHeight);
      }
      return Math.round(aspectRatio > 1 ? originalImageWidth : originalImageHeight * aspectRatio);
    }
    return ratioWidth;
  }, [originalImageWidth, originalImageHeight, currentLayoutMedia]);

  const fitImageHeight = useMemo(() => {
    const ratioWidth = currentLayoutMedia?.restrictions?.ratio_w || 1;
    const ratioHeight = currentLayoutMedia?.restrictions?.ratio_h || 1;
    if (originalImageWidth && originalImageHeight && ratioWidth && ratioHeight) {
      const aspectRatio = ratioWidth / ratioHeight;
      if (aspectRatio === 1) {
        return Math.min(originalImageWidth, originalImageHeight);
      }
      return Math.round(aspectRatio > 1 ? originalImageWidth / aspectRatio : originalImageHeight);
    }
    return ratioHeight;
  }, [originalImageWidth, originalImageHeight, currentLayoutMedia]);

  const isReady =
    originalImageWidth &&
    originalImageHeight &&
    fitImageWidth &&
    fitImageHeight &&
    previewWidth &&
    previewHeight;
  const ratioWidth = currentLayoutMedia?.restrictions?.ratio_w || 1;
  const ratioHeight = currentLayoutMedia?.restrictions?.ratio_h || 1;
  const aspectRatio = (ratioWidth || 1) / (ratioHeight || 1);

  const previewUrl = useMemo(() => {
    if (displayUrls[resolvedPreviewUrl] && isDrawerActive) {
      const preview = displayUrls[resolvedPreviewUrl];
      return createImageFitLink({
        resolvedUrl: preview.url,
        filterType: imageFit.filterType,
        filestackPolicy,
        imageWidth: fitImageWidth || ratioWidth,
        imageHeight: fitImageHeight || ratioHeight
      });
    }
    return undefined;
  }, [
    displayUrls,
    resolvedPreviewUrl,
    imageFit.filterType,
    fitImageHeight,
    fitImageWidth,
    isDrawerActive
  ]);

  useEffect(() => {
    if (previewUrl && resolvedUrl) {
      (async () => {
        const handle = resolvedUrl.split("/").pop();
        const dimensions: Dimensions = await fileStackFetchImageSizePromise(
          handle as string,
          filestackPolicy
        );

        setOriginalImageHeight(dimensions?.height || 0);
        setOriginalImageWidth(dimensions?.width || 0);
      })();
    }
  }, [previewUrl, resolvedUrl, filestackPolicy]);

  useEffect(() => {
    let timeoutId: any;

    const start = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (imageRef.current && isDrawerActive) {
        timeoutId = setTimeout(function () {
          if (imageRef.current?.clientHeight) {
            setPreviewHeight(imageRef.current?.clientHeight || 0);
            setPreviewWidth(imageRef.current?.clientWidth || 0);
          } else {
            start();
          }
        }, 500);
      }
    };
    if (isDrawerActive) {
      start();
    }
    return () => {
      if (timeoutId) {
        clearInterval(timeoutId);
      }
    };
  }, [imageRef.current, isDrawerActive, imageFit.filterType]);

  useEffect(() => {
    if (isDrawerActive && imageFit.initialUpdate === false) {
      saveAndPatchScene();
    }
  }, [
    imageFit.fitType,
    imageFit.filterType,
    isDrawerActive,
    imageFit.x,
    imageFit.y,
    imageFit.width,
    imageFit.height,
    imageFit.initialUpdate
  ]);
  useEffect(() => {
    if (isDrawerActive && isReady) {
      const fitType = modification?.fit || FitOptions.smart_crop;
      const filterType = modification?.filter_type;
      const defaultWidth =
        aspectRatio === 1
          ? Math.min(previewWidth, previewHeight)
          : Math.round(aspectRatio >= 1 ? previewWidth : previewHeight * aspectRatio);
      const defaultHeight =
        aspectRatio === 1
          ? Math.min(previewWidth, previewHeight)
          : Math.round(aspectRatio >= 1 ? previewWidth / aspectRatio : previewHeight);

      // imageFit is the actual values of server
      const imageFit = {
        fitType,
        filterType,
        x: modification?.x || 0,
        y: modification?.y || 0,
        width: modification?.width || fitImageWidth,
        height: modification?.height || fitImageHeight
      };
      dispatch(scenesActions.updateImageFit(imageFit));
      if (modification?.fit === FitOptions.crop) {
        const calculatedWidth = Math.round(
          ((imageFit?.width || 0) * previewWidth) / originalImageWidth
        );
        const calculatedHeight = Math.round(
          ((imageFit?.height || 0) * previewHeight) / originalImageHeight
        );

        const calculatedX = Math.round(((imageFit.x || 0) * previewWidth) / originalImageWidth);
        const calculatedY = Math.round(((imageFit.y || 0) * previewHeight) / originalImageHeight);
        setCrop({
          unit: "px",
          x: calculatedX,
          y: calculatedY,
          width: calculatedWidth || defaultWidth,
          height: calculatedHeight || defaultHeight
        });
      } else {
        setCrop({
          unit: "px",
          x: 0,
          y: 0,
          width: defaultWidth,
          height: defaultHeight
        });
      }
    } else if (!isDrawerActive) {
      dispatch(scenesActions.cleanImageFit());
      setCrop(undefined);
      setPreviewHeight(0);
      setPreviewWidth(0);
      setOriginalImageWidth(0);
      setOriginalImageHeight(0);
      setOnLoadingImage(true);
    }
  }, [isDrawerActive, isReady]);

  const link = useMemo(() => {
    if (!isDrawerActive || !isReady) {
      return "";
    }
    return createImageFitLink({
      resolvedUrl,
      fitType: imageFit.fitType,
      imageWidth: fitImageWidth || ratioWidth,
      imageHeight: fitImageHeight || ratioHeight,
      crop: {
        x: imageFit.x || 0,
        y: imageFit.y || 0,
        width: imageFit.width || fitImageWidth,
        height: imageFit.height || fitImageHeight
      },
      filterType: imageFit.filterType,
      filestackPolicy
    });
  }, [imageFit, resolvedUrl, isDrawerActive, fitImageWidth, fitImageHeight, isReady]);

  const onClickBackButton = () => {
    openDrawer(backDrawer);
  };

  const saveAndPatchScene = async () => {
    const isCropPositionChanged =
      modification &&
      imageFit.x === modification.x &&
      imageFit.y === modification.y &&
      imageFit?.width === modification.width &&
      imageFit?.height === modification.height;

    if (
      modification &&
      imageFit.filterType === modification.filter_type &&
      imageFit.fitType === modification.fit &&
      (imageFit.fitType !== FitOptions.crop || isCropPositionChanged)
    ) {
      return;
    }

    const isFitTypeCrop = imageFit.fitType === FitOptions.crop;

    const operations: PatchOperation[] = [
      {
        op: "replace",
        path: `attributes.${mediaAttributeType}.${assetKey}`,
        value: {
          ...scene?.attributes?.[mediaAttributeType]?.[assetKey],
          modification: {
            x: isFitTypeCrop ? imageFit.x || 0 : undefined,
            y: isFitTypeCrop ? imageFit.y || 0 : undefined,
            width: (isFitTypeCrop ? imageFit?.width : fitImageWidth) || ratioWidth || 600,
            height: (isFitTypeCrop ? imageFit?.height : fitImageHeight) || ratioHeight || 600,
            fit: imageFit.fitType,
            filter_type: imageFit.filterType
          }
        }
      }
    ];

    dispatch(
      analyticsEvents.imageFitChange({
        fitType: imageFit.fitType as FitOptions,
        filterType: imageFit.filterType as FilterType,
        sceneId: scene?.id as string
      })
    );

    dispatch(
      scenesActions.patchSceneRequest({
        draftId: draft?.id as string,
        sceneId: scene?.id as string,
        operations
      })
    );
  };

  const debounceRequest = useCallback(
    debounce((value: Crop) => {
      const newCrop = {
        ...value,
        width: Math.round((value.width / previewWidth) * originalImageWidth),
        height: Math.round((value.height / previewHeight) * originalImageHeight),
        x: Math.round((value.x / previewWidth) * originalImageWidth),
        y: Math.round((value.y / previewHeight) * originalImageHeight)
      };

      dispatch(
        scenesActions.updateImageFit({
          x: newCrop.x,
          y: newCrop.y,
          width: newCrop.width,
          height: newCrop.height
        })
      );
    }, 750),
    [originalImageWidth, originalImageHeight, previewHeight, previewWidth]
  );

  const onChangeCrop = (localCrop: Crop) => {
    debounceRequest(localCrop);
    setCrop(localCrop);
  };

  const onImageLoad = () => {
    setOnLoadingImage(false);
  };

  const onSelectFitType = (type: FitOptions) => {
    analyticsEvents.selectImageFitType({ type });
    dispatch(scenesActions.updateImageFit({ fitType: type }));
  };
  return (
    <StyledFlexDrawer
      right={right}
      active={isDrawerActive}
      width={width}
      className="image-fit-drawer"
    >
      {!isReady && <CircleLoader />}

      <H1_FlexColumn gap="30px" overflow="auto">
        <H1_FlexRow flex="0 0 auto" gap="9px">
          <GoBackButton
            label={intl.formatMessage(imageFitDrawerMessages.backToScene)}
            onClick={onClickBackButton}
            fontSize="16px"
          />
        </H1_FlexRow>
        <H1_FlexColumn gap="20px">
          <ReactCropFlexColumn
            justify="center"
            align="center"
            position="absolute"
            width="calc(186px * 16 / 9)"
            $opacity={isCropVisible}
            $background={theme.gray2}
          >
            <StyledReactCrop
              $width={previewWidth > 0 ? `${previewWidth}px` : "100%"}
              $height={previewHeight > 0 ? `${previewHeight}px` : "100%"}
              minHeight={10}
              minWidth={10}
              crop={crop}
              onChange={onChangeCrop}
              aspect={fitImageWidth / fitImageHeight}
            >
              <FixedImage
                ref={imageRef}
                src={previewUrl as string}
                onLoad={onImageLoad}
                $aspect={originalImageWidth / originalImageHeight}
              />
            </StyledReactCrop>
          </ReactCropFlexColumn>
          <ConditionalRender condition={onLoadingImage && !!isReady}>
            <ImageLoadingWrapper position="absolute" width="calc(186px * 16 / 9)" height="186px">
              <CircleLoader size="50%" />
            </ImageLoadingWrapper>
          </ConditionalRender>
          <ReactCropFlexColumn
            position="absolute"
            width="calc(186px * 16 / 9)"
            height="186px"
            $opacity={isLinkImageVisible}
            $background="var(--gray-02)"
          >
            <LinkImage
              onLoad={() => setLinkImageLoading(false)}
              src={link}
              $opacity={!!link}
              $aspect={fitImageWidth / fitImageHeight}
            />
          </ReactCropFlexColumn>
          {/* Fit types */}
          <ImageFitType onSelectFitType={onSelectFitType} />

          {/* Filters */}
          <ImageFitFilterType />
        </H1_FlexColumn>
      </H1_FlexColumn>
    </StyledFlexDrawer>
  );
};

export default ImageFitDrawer;
