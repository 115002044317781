import { ChannelsTabs, defiance, DEFIANCE, FeatureFlag, LiveVideoMode, Location } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Tab } from "@nextui-org/tabs";
import React from "react";
import styled from "styled-components";
import { Button, Tabs } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import WatchNextModal from "app/pages/CGN/WatchNextModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import UploadVideoModal from "app/pages/CGN/UploadVideoModal";
import { cgnActions } from "app/store/slices/cgn.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { Tooltip } from "antd";
import useSmallScreen from "app/hooks/useSmallScreen";

const BlackBackgroundFlexRow = styled(H1_FlexRow)<{ $isTransparent?: boolean; $isOpac?: boolean }>`
  background-color: ${({ $isTransparent, $isOpac }) =>
    $isTransparent && !$isOpac ? "transparent" : "rgb(20, 20, 20)"};
  background-image: ${({ $isTransparent, $isOpac }) =>
    $isTransparent && !$isOpac
      ? "linear-gradient(180deg, rgba(0, 0, 0, .7) 10%, transparent)"
      : "none"};
  transition: background-color 0.3s ease-in-out, background-image 0.3s ease-in-out;
`;
const WhiteButton = styled(Button)`
  color: white;
`;

const LiveTabs = styled(Tabs)`
  && > div {
    background-color: ${({ theme }) => theme.gray2};
    padding: 4px;
    border-radius: 8px;

    div[data-slot="tabContent"] {
      color: ${({ theme }) => theme.gray6};
    }

    button[aria-selected="true"] > [data-slot="cursor"] {
      color: ${({ theme }) => theme.gray10};
      background-color: ${({ theme }) => theme.gray4}; /* Darker background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Darker shadow */
      opacity: 1;

      ~ div[data-slot="tabContent"] {
        color: ${({ theme }) => theme.gray10};
      }
    }
  }
`;

const LiveContainer = styled(H1_FlexRow)`
  border-radius: 14px;
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  opacity: 0.8;
  svg {
    width: fit-content;
  }
`;

const RedIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f31260;
`;

const FlexLink = styled(Link)`
  display: flex;
  svg {
    width: fit-content;
  }
`;

const StyledHourOneLogo = styled(HourOneLogo)<{ $isMainPage: boolean }>`
  g path {
    fill: ${(props) => (props.$isMainPage ? "white" : props.theme.gray11)};
  }
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

interface CGNHeaderProps {
  activeTab: ChannelsTabs | DEFIANCE;
  onChangeActiveTab: (tab: ChannelsTabs | DEFIANCE) => void;
  isMainPage?: boolean;
  onChangeVideoMode: (videoMode: LiveVideoMode) => void;
  videoMode?: LiveVideoMode;
  setIsModalOpen?: (isOpen: boolean) => void;
  isScrolled: boolean;
}

const CGNHeader = ({
  activeTab,
  onChangeActiveTab,
  isMainPage = false,
  onChangeVideoMode,
  videoMode,
  setIsModalOpen,
  isScrolled
}: CGNHeaderProps) => {
  const [showPromptModal, setShowPromptModal] = React.useState(false);
  const [showUploadVideoModal, setShowUploadVideoModal] = React.useState(false);
  const navigate = useNavigate();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();

  const CGNInternalUse = useAppSelector((state) => state.cgn.CGNInternalUse);

  const onClickLiveButton = () => {
    setShowPromptModal(true);
    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
  };

  const onClickUploadVideoButton = () => {
    setShowUploadVideoModal(true);
    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setShowPromptModal(false);
    setShowUploadVideoModal(false);
    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  const onCategory = (name: ChannelsTabs | DEFIANCE) => {
    if (videoMode === LiveVideoMode.Player) {
      navigate(`${Location.Live}/${name}`);
    } else if (videoMode === LiveVideoMode.FullScreen) {
      onChangeActiveTab(name);
    }
  };

  const onSwitchChange = (value: boolean) => {
    if (onChangeVideoMode) {
      onChangeVideoMode(value ? LiveVideoMode.Player : LiveVideoMode.FullScreen);
    }
  };

  const onClickLive = () => {
    if (flags[FeatureFlag.cgnInternal]) {
      dispatch(cgnActions.setCGNInternalUse(!CGNInternalUse));
    } else {
      navigate(`${Location.Live}`);
    }
  };

  return (
    <>
      <WatchNextModal visible={showPromptModal} onClose={onCloseModal} />
      <UploadVideoModal visible={showUploadVideoModal} onClose={onCloseModal} />
      <BlackBackgroundFlexRow
        $isTransparent={!isScrolled}
        $isOpac={!isMainPage}
        flex="0 0 60px"
        height="80px"
        justify="space-between"
        padding="10px 30px"
        align="center"
      >
        <H1_FlexRow gap="10px" align="center" position="relative">
          <FlexLink to={Location.Home}>
            <StyledHourOneLogo $isMainPage={isMainPage} width="100%" height={38} />
          </FlexLink>
          <LiveContainer
            height="28px"
            width="76px"
            gap="8px"
            justify="center"
            align="center"
            padding="0 8px"
            onClick={onClickLive}
          >
            <RedIcon />
            <H1_TextXs color="white">LIVE</H1_TextXs>
          </LiveContainer>
        </H1_FlexRow>
        <ConditionalRender condition={isMainPage && !isSmallScreen}>
          <H1_FlexRow gap="8px" align="center">
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.news)}>
              News
            </WhiteButton>
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.podcast)}>
              Podcasts
            </WhiteButton>
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.entertainment)}>
              Entertainment
            </WhiteButton>
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.talk_show)}>
              Talk Show
            </WhiteButton>
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.technology)}>
              Technology
            </WhiteButton>
            <WhiteButton variant="light" onClick={() => onCategory(ChannelsTabs.public)}>
              Public
            </WhiteButton>
            <ConditionalRender condition={flags[FeatureFlag.cgnVodUpload]}>
              <WhiteButton variant="light" onClick={() => onCategory(defiance)}>
                Defiance Media
              </WhiteButton>
            </ConditionalRender>
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={!isMainPage && !isSmallScreen}>
          <LiveTabs
            variant="underlined"
            selectedKey={activeTab}
            onSelectionChange={(value) => onChangeActiveTab(value as ChannelsTabs)}
          >
            <Tab key={ChannelsTabs.news} title={"News"} />
            <Tab key={ChannelsTabs.podcast} title={"Podcast"} />
            <Tab key={ChannelsTabs.entertainment} title={"Entertainment"} />
            <Tab key={ChannelsTabs.talk_show} title={"Talk Show"} />
            <Tab key={ChannelsTabs.technology} title={"Technology"} />
            <Tab key={ChannelsTabs.public} title={"Public"} />
            {flags[FeatureFlag.cgnVodUpload] && <Tab key={defiance} title="Defiance Media" />}
          </LiveTabs>
        </ConditionalRender>
        <H1_FlexRow gap="5px" align="center">
          <ConditionalRender condition={videoMode === LiveVideoMode.Player && !isSmallScreen}>
            <Tooltip title="Theater Mode">
              <>
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => onSwitchChange(false)}
                  startContent={<H1_Icon icon="far fa-rectangle" color="#F4F4F5" />}
                />
              </>
            </Tooltip>
          </ConditionalRender>
          <ConditionalRender condition={videoMode === LiveVideoMode.FullScreen && !isSmallScreen}>
            <Tooltip title="Default View">
              <>
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => onSwitchChange(true)}
                  startContent={<H1_Icon icon="far fa-list" color="#F4F4F5" />}
                />
              </>
            </Tooltip>
          </ConditionalRender>
          <ConditionalRender condition={flags[FeatureFlag.cgnVodUpload] && !isSmallScreen}>
            <Button
              variant="light"
              isIconOnly
              onClick={onClickUploadVideoButton}
              startContent={<H1_Icon color="white" icon="fas fa-cloud-arrow-up" />}
            />
          </ConditionalRender>
          <Button
            color="default"
            variant="light"
            startContent={<H1_Icon color="white" icon="fas fa-sparkles" />}
            onClick={onClickLiveButton}
          >
            <H1_TextSmall color="white">Gen TV</H1_TextSmall>
          </Button>
        </H1_FlexRow>
      </BlackBackgroundFlexRow>
    </>
  );
};

export default CGNHeader;
