import React, { useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import styled, { css } from "styled-components";
import TitledInput from "app/components/common/TitledInput";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { charactersActions } from "app/store/slices/characters.slice";
import { charactersGlobalSelectors, voicesGlobalSelectors } from "app/store/adapters/adapters";
import { CustomVoice, FeatureFlag, FoldersContext, Gender, Location, Voice } from "app/types";
import UseFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { fetchingStatus, isMediaPlaying } from "app/utils/helpers";
import { voicesActions } from "app/store/slices/voices.slice";
import TextArea from "antd/lib/input/TextArea";
import { v4 as uuidv4 } from "uuid";
import { playgroundActions } from "app/store/slices/playground.slice";
import {
  AUTO_DETECT,
  DubbingLanguages,
  DubbingRequestSourceTypes,
  PlayGroundRequest,
  PlaygroundScriptTab,
  PlaygroundVideoTypeTabs,
  VideoRequestType
} from "app/types/playground";
import ConditionalRender from "app/components/common/ConditionalRender";
import { validatePlayGround } from "app/pages/PlayGround/helpers";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import PlaygroundTopBar from "app/pages/PlayGround/PlaygroundTopBar";
import ButtonBox from "./ButtonBox";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import RecordCharacterModal from "app/pages/PlayGround/RecordCharacterModal";
import { useNavigate } from "react-router-dom";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import "./playground.scss";
import PlaygroundVideosList from "app/pages/PlayGround/PlaygroundVideosList";
import { getAllVideosSortedByDate } from "app/store/selectorsV2/playground.selectors";
import PlaygroundPresenter from "app/pages/PlayGround/PlaygroundPresenter";
import TranscriptAudioUploadPlaygroundModal from "app/pages/PlayGround/TranscriptAudioUploadPlaygroundModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import PlaygroundSelectExistingVideo from "app/pages/PlayGround/PlaygroundSelectExistingVideo";
import PlaygroundFtuModal from "app/pages/PlayGround/PlaygroundFtuModal";
import { userActions } from "app/store/slices/user.slice";
import PlaygroundTargetLanguage from "app/pages/PlayGround/PlaygroundTargetLanguage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button, type Selection, Tab, Tabs } from "@nextui-org/react";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import useMediaLibraryUploader from "app/pages/mediaLibrary/useMediaLibraryUploader";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import PlaygroundSelectExistingImage from "app/pages/PlayGround/PlaygroundSelectExistingImage";
import { MediaType } from "app/types/media";
import PlaygroundFooterArea from "app/pages/PlayGround/PlaygroundFooterArea";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import PlaygroundRealTime from "app/pages/PlayGround/realTime/PlaygroundRealTime";

const VIDEO_TITLE = "HourOne Video";
const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background: #18181b;
  height: 100vh;
  overflow: hidden;
`;

const MainFlexColumn = styled(H1_FlexColumn)`
  z-index: 5;
`;

const CenterFlexColumn = styled(H1_FlexColumn)<{ $isBottom: boolean }>`
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(-50vh + 186px));
  ${({ $isBottom }) =>
    $isBottom &&
    css`
      transform: translate(-50%, 0);
    `};
`;

const HiddenAudioPlayer = styled(H1_FlexRow)`
  display: none;
`;

const StyledTabs = styled(Tabs)`
  button[data-slot="tab"] {
    padding: 0;
    height: 20px;
  }
  span[data-slot="cursor"] {
    background-color: #71717a;
  }
  &&& div[data-slot="tabContent"] {
    color: #71717a;
  }
  &&&&& ~ {
    padding: 0;
  }
`;

const ContentFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: #27272a;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  height: 243px;
  min-width: 250px;
`;

const PlaygroundTypeButton = styled(Button)<{ $selected: boolean }>`
  border-radius: 15px;
  background-color: #393949;
  border: 1px solid ${({ $selected }) => ($selected ? "white" : "transparent")};
  color: ${({ $selected }) => ($selected ? " white" : "#B2B2B2")};
  i {
    color: ${({ $selected }) => ($selected ? "white" : "#B2B2B2")};
  }
  &:hover {
    color: white;
  }
`;

const StyledTextArea = styled(TextArea)<{ $error?: boolean }>`
  resize: none;
  overflow: auto;
  flex: 1 0 128px;
  border-radius: 4px;
  border: ${({ $error }) => ($error ? "1px solid red" : "1px solid #71717a")};
  background: transparent;
  color: #d9d9d9;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-size: 16px;
  line-height: 25px;
`;

const FlexColumnFormContainer = styled(H1_FlexColumn)`
  max-width: 600px;
  align-self: center;
`;

const HiddenFutureUseItems = styled(H1_FlexRow)`
  min-height: 0px;
  display: none;
`;

const PlayGroundPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<DubbingLanguages>(
    DubbingLanguages.SPANISH
  );
  const [sourceLanguage, setSourceLanguage] = useState<DubbingLanguages | typeof AUTO_DETECT>(
    AUTO_DETECT
  );
  const [showFtuModal, setShowFtuModal] = useState(false);
  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [voiceIdLoading, setVoiceIdLoading] = useState<string>("");
  const [voiceIdPlaying, setVoiceIdPlaying] = useState<string>("");
  const [previewVoice, setPreviewVoice] = useState<Partial<Voice> | undefined>(undefined);
  const [model, setModel] = useState<string | undefined>("bison-hope2");
  const [isModelChanged, setIsModelChanged] = useState<boolean>(false);
  const [lively, setLively] = useState(false);
  const [superRes, setSuperRes] = useState(false);
  const [isFullTranscriptPreviewPlaying, setIsFullTranscriptPreviewPlaying] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<PlaygroundVideoTypeTabs>(
    PlaygroundVideoTypeTabs.AI_Video
  );
  const [showCharacterUploadModal, setShowCharacterUploadModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    transcript?: string;
    selectedVoice?: string;
    selectedPresenter?: string;
  }>({});
  const audioPreviewRef = useRef<HTMLAudioElement>(null);
  const customAudioPreviewRef = useRef<HTMLAudioElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const flags = useFlags();

  const isTabTranslate = activeTab === PlaygroundVideoTypeTabs.Translate;
  const isTabLivePortrait = activeTab === PlaygroundVideoTypeTabs.Live_Portrait;
  const isTabAiVideo = activeTab === PlaygroundVideoTypeTabs.AI_Video;
  const isTabSpeakingVideo = activeTab === PlaygroundVideoTypeTabs.Speaking_Video;
  const isTabRealTime = activeTab === PlaygroundVideoTypeTabs.RealTime;
  const isTabRealTimeV2 = activeTab === PlaygroundVideoTypeTabs.RealTimeV2;

  const {
    file: voiceFile,
    reset: resetVoiceRecord,
    onSelectFile: onSelectFileVoice,
    importLoading: importLoadingVoice,
    fileStoreResult: fileStoreResultVoice,
    executeImport
  } = UseFileUploadFilestack({ autoUpload: false });

  const preferences = useAppSelector((state) => state.user.preferences);
  const videos = useAppSelector(getAllVideosSortedByDate);
  const voicePreviewUrl = useAppSelector((state) => state.playground.voicePreviewUrl);
  const selectedPresenterId = useAppSelector((state) => state.playground.selectedPresenterId);
  const selectedVoiceId = useAppSelector((state) => state.playground.selectedVoiceId);
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voicesGlobalSelectors.selectById(state, selectedVoiceId || "")
  );
  const generateStatus = useAppSelector((state) => state.playground.generateStatus);
  const getVideosStatus = useAppSelector((state) => state.playground.getVideosStatus);
  const selectedGender = useAppSelector((state) => state.playground.selectedGender);
  const previewTranscriptStatus = useAppSelector(
    (state) => state.playground.previewTranscriptStatus
  );
  const transcript = useAppSelector((state) => state.playground.transcript);
  const customPresenter = useAppSelector((state) => state.playground.customPresenter);
  const livePortrait = useAppSelector((state) => state.playground.livePortrait);
  const customVoice = useAppSelector((state) => state.playground.customVoice);
  const characters = useAppSelector(charactersGlobalSelectors.selectAll);
  const voices: Voice[] = useAppSelector(voicesGlobalSelectors.selectAll);
  const limits = useAppSelector(getLimits);

  const isCustomPresenterSet =
    !!customPresenter && customPresenter.id && activeTab === PlaygroundVideoTypeTabs.Speaking_Video;
  const isCustomVoiceSet = !!customVoice && customVoice.id === selectedVoiceId;
  const pageLoading = getVideosStatus === fetchingStatus.loading;

  const {
    executeImport: executeImportPresenter,
    importLoading: importLoadingPresenter,
    progress: importProgressPresenter,
    uploadedMedia: uploadedPresenter,
    resetUploader: resetPresenterUploaded
  } = useMediaLibraryUploader({ context: FoldersContext.playground });

  const {
    executeImport: executeImportImage,
    importLoading: importLoadingImage,
    progress: importProgressImage,
    uploadedMedia: uploadedImage,
    resetUploader: resetImageUploaded
  } = useMediaLibraryUploader({ context: FoldersContext.playground });

  const { displayUrls, loading: LoadingDisplayUrlPresenter } = useDisplayUrls([
    uploadedPresenter?.url
  ]);
  const customPresenterUrl: MediaUrl | undefined = displayUrls[uploadedPresenter?.url as string];
  const customImageUrl: MediaUrl | undefined = displayUrls[uploadedImage?.url as string];

  const generateButtonLoading =
    LoadingDisplayUrlPresenter ||
    importLoadingPresenter ||
    importLoadingImage ||
    generateStatus === fetchingStatus.loading;
  const isGenerateButtonDisabled = isTabLivePortrait && !livePortrait && !customPresenter;

  useEffect(() => {
    dispatch(mediaActions.getMediaListRequest({ context: FoldersContext.playground }));
    dispatch(charactersActions.getCharactersListRequest());
    dispatch(voicesActions.getVoicesListRequest());
    dispatch(playgroundActions.playgroundGetVideosRequest());
    dispatch(playgroundActions.playgroundGetModelsListRequest());
    return () => {
      dispatch(mediaActions.invalidMediaRootCache());
    };
  }, []);
  useEffect(() => {
    if (activeTab === PlaygroundVideoTypeTabs.Live_Portrait) {
      dispatch(
        playgroundActions.updatePlayGroundDetails({
          customPresenter: {
            id: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN",
            name: "User video",
            image: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN",
            url: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN"
          }
        })
      );
      if (!isModelChanged) {
        setModel("bison-hope2");
      }
    } else if (!isModelChanged) {
      if (activeTab === PlaygroundVideoTypeTabs.Translate) {
        setModel("falcon_jaguar_1500000");
      } else {
        setModel("bison-hope2");
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (preferences?.playground_ftu) {
      dispatch(userActions.updateUserPreferencesRequest({ playground_ftu: false }));
      setShowFtuModal(true);
    }
  }, [preferences?.playground_ftu]);

  useEffect(() => {
    if (previewTranscriptStatus === fetchingStatus.succeeded) {
      dispatch(playgroundActions.updatePreviewTranscriptStatusToIdle());
    }
    if (previewTranscriptStatus === fetchingStatus.failed) {
      dispatch(playgroundActions.updatePreviewTranscriptStatusToIdle());
    }
  }, [previewTranscriptStatus]);
  useEffect(() => {
    if (fileStoreResultVoice) {
      setShowVoiceModal(false);
      dispatch(
        playgroundActions.updatePlayGroundDetails({
          customVoice: {
            id: fileStoreResultVoice.handle,
            display_name: "User audio",
            image: "https://flagcdn.com/us.svg",
            url: fileStoreResultVoice.url
          },
          selectedVoice: fileStoreResultVoice.handle
        })
      );
    }
  }, [fileStoreResultVoice]);

  useEffect(() => {
    if (videos.length && !selectedPresenterId) {
      const lastVideo = videos.at(-1);
      if (!lastVideo?.source_video && !lastVideo?.voice_url) {
        dispatch(playgroundActions.updateCurrentFormStateByVideo(lastVideo));
      }
    }
  }, [videos, selectedPresenterId]);

  useEffect(() => {
    if (generateStatus === fetchingStatus.succeeded) {
      dispatch(playgroundActions.setGenerateToIdle());
    } else if (generateStatus === fetchingStatus.failed) {
      dispatch(playgroundActions.setGenerateToIdle());
    }
  }, [generateStatus]);

  useEffect(() => {
    if (uploadedPresenter && customPresenterUrl && customPresenterUrl.thumbnail) {
      if (customPresenterUrl.assetType === MediaType.video) {
        dispatch(
          playgroundActions.updatePlayGroundDetails({
            customPresenter: {
              id: uploadedPresenter.url,
              name: "User video",
              image: customPresenterUrl.thumbnail,
              url: uploadedPresenter.url
            }
          })
        );
      }
      resetPresenterUploaded();
    }
  }, [uploadedPresenter, customPresenterUrl]);

  useEffect(() => {
    if (uploadedImage && customImageUrl && customImageUrl.thumbnail) {
      if (customImageUrl.assetType === MediaType.image) {
        dispatch(
          playgroundActions.updatePlayGroundDetails({
            livePortrait: {
              id: uploadedImage.url,
              name: "Live Portrait",
              image: customImageUrl.thumbnail,
              url: uploadedImage.url
            }
          })
        );
      }
      resetImageUploaded();
    }
  }, [uploadedImage, customImageUrl]);

  const voiceFileAsUrl = useMemo(() => {
    if (voiceFile) {
      return URL.createObjectURL(voiceFile);
    }
    return undefined;
  }, [voiceFile]);

  const charactersOptions = useMemo(() => {
    const result = characters.map((c) => {
      return {
        name: c.title,
        image: c.image,
        id: c.character_id,
        url: "",
        gender: c.gender
      };
    });
    return result;
  }, [characters]);

  useEffect(() => {
    if (voicePreviewUrl) {
      // Voice url changes, so the audio element is now being loaded, we want to prevent the loading icon to be over
      setIsFullTranscriptPreviewPlaying(true);
    }
  }, [voicePreviewUrl]);

  const onCloseVoiceModal = () => {
    setShowVoiceModal(false);
  };

  const onVoicePreviewClicked = (previewVoiceClicked: Partial<Voice>) => {
    if (previewVoice?.id === previewVoiceClicked.id) {
      const audio = audioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        setVoiceIdPlaying("");
        audio.pause();
      } else {
        setVoiceIdPlaying(previewVoiceClicked.id as string);
        audio.play();
      }
    } else {
      setPreviewVoice(previewVoiceClicked);
      setVoiceIdLoading(previewVoiceClicked.id as string);
    }
  };

  const onGenderChange = (gender: Gender) => {
    dispatch(playgroundActions.updatePlayGroundDetails({ selectedGender: gender }));
  };
  const onSelectLanguage = (target: DubbingLanguages) => {
    setSelectedLanguage(target);
  };

  const onSelectSourceLanguage = (target: DubbingLanguages) => {
    setSourceLanguage(target);
  };
  const onScriptChange = (evt: any) => {
    const { value } = evt.target;
    dispatch(googleEvents.playgroundTranscriptChange({ value }));
    dispatch(playgroundActions.updatePlayGroundDetails({ transcript: value || "" }));
    dispatch(playgroundActions.cleanPlaygroundAudio());
  };
  const executeValidation = () => {
    const validationResult = validatePlayGround(
      transcript || "",
      limits.chars_per_scene,
      isCustomVoiceSet,
      selectedVoiceId,
      selectedPresenterId
    );
    setValidationErrors(validationResult);
    return validationResult;
  };

  const onPreviewText = () => {
    dispatch(
      googleEvents.playgroundPreviewTranscript({ transcript, selectedVoiceId, isCustomVoiceSet })
    );
    setPreviewVoice(undefined);
    if (isCustomVoiceSet) {
      const audio = customAudioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
        setVoiceIdPlaying("");
        setIsFullTranscriptPreviewPlaying(false);
      } else {
        audio.play();
        setIsFullTranscriptPreviewPlaying(true);
      }
    } else if (voicePreviewUrl) {
      const audio = audioPreviewRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
        setVoiceIdPlaying("");
        setIsFullTranscriptPreviewPlaying(false);
      } else {
        audio.play();
        setIsFullTranscriptPreviewPlaying(true);
      }
    } else {
      dispatch(
        playgroundActions.previewTranscriptRequest({
          orderId: uuidv4(),
          voiceId: selectedVoiceId as string,
          sml: transcript as string
        })
      );
    }
  };

  const onGenerate = async () => {
    const orderId = uuidv4();
    const request: PlayGroundRequest = { order_id: orderId };
    request.title = VIDEO_TITLE;

    if (isTabTranslate) {
      dispatch(
        googleEvents.playgroundGenerateVideo({
          translateLanguage: selectedLanguage,
          customPresenter
        })
      );
      request.source_video = customPresenter?.url;
      request.voice_url = customPresenter?.url;
      request.model_id = model;
      request.dubbing = {
        source_type: DubbingRequestSourceTypes.file,
        source_lang: sourceLanguage === AUTO_DETECT ? null : sourceLanguage,
        target_lang: selectedLanguage
      };
      request.type = VideoRequestType.source_video;
      dispatch(playgroundActions.generateRequest(request));
      return;
    }
    const validationResult = executeValidation();
    if (Object.keys(validationResult).length) {
      return;
    }

    const voice: Partial<CustomVoice> = isCustomVoiceSet ? customVoice : (selectedVoice as Voice);
    const presenter = isCustomPresenterSet
      ? customPresenter
      : charactersOptions.find((presenter) => presenter.id === selectedPresenterId);
    dispatch(
      googleEvents.playgroundGenerateVideo({
        transcript,
        selectedVoiceId,
        isCustomVoiceSet,
        isCustomPresenterSet,
        customPresenter,
        model_id: model,
        lively,
        apply_super_res: superRes
      })
    );
    if (voice.url) {
      request.voice_url = voice.url;
    } else {
      request.transcript = transcript;
      request.voice_id = selectedVoiceId;
    }
    if (presenter?.url) {
      request.source_video = presenter?.url;
    } else {
      request.character_id = presenter?.id;
    }
    if (isTabLivePortrait && livePortrait && customPresenter) {
      request.source_image_url = livePortrait.url;
      request.driving_video_url = customPresenter.url;
      request.type = VideoRequestType.portrait;
    }
    request.apply_super_res = superRes;
    request.lively = lively;
    request.model_id = model;

    if (activeTab === PlaygroundVideoTypeTabs.AI_Video) {
      request.type = VideoRequestType.avatar;
    } else if (activeTab === PlaygroundVideoTypeTabs.Speaking_Video) {
      request.type = VideoRequestType.source_video;
    }

    dispatch(playgroundActions.generateRequest(request));
  };

  const onCanPlayCustomVoice = () => {
    customAudioPreviewRef.current?.play();
    setIsFullTranscriptPreviewPlaying(true);
  };

  const onCanPlayPreview = () => {
    audioPreviewRef.current?.play();
    if (previewVoice) {
      setVoiceIdPlaying(previewVoice.id as string);
    } else {
      setIsFullTranscriptPreviewPlaying(true);
    }
    setVoiceIdLoading("");
  };

  const onEndedAudio = () => {
    setIsFullTranscriptPreviewPlaying(false);
    setVoiceIdPlaying("");
  };

  const onCloseUploadCharacterModal = () => {
    setShowCharacterUploadModal(false);
  };
  const onRecordPresenter = () => {
    setShowCharacterUploadModal(true);
  };

  const onBack = () => {
    navigate(Location.Home);
  };

  const onModelSelection = (value: Selection) => {
    const parsedValue = Array.from(value)[0] as string;
    setModel(parsedValue);
    setIsModelChanged(true);
  };

  return (
    <BackgroundFlexColumn flex="0 0 auto" overflow="auto" position="relative" padding="0 0 43px 0">
      <PlaygroundFtuModal visible={showFtuModal} onClose={() => setShowFtuModal(false)} />
      <RecordCharacterModal
        open={showCharacterUploadModal}
        closeModal={onCloseUploadCharacterModal}
        onSelectFile={() => {
          return;
        }}
      />
      <PlaygroundTopBar
        onBack={onBack}
        onModelSelection={onModelSelection}
        onChangeLively={setLively}
        onChangeSuperRes={setSuperRes}
        lively={lively}
        superRes={superRes}
        model={model}
      />
      <ConditionalRender condition={videos.length > 0 && !isTabRealTime && !isTabRealTimeV2}>
        <PlaygroundVideosList />
      </ConditionalRender>
      <ConditionalRender condition={isTabRealTime}>
        <PlaygroundRealTime useBeta={false} />
      </ConditionalRender>
      <ConditionalRender condition={isTabRealTimeV2}>
        <PlaygroundRealTime useBeta={true} />
      </ConditionalRender>
      <MainFlexColumn
        position="relative"
        align="center"
        justify="center"
        flex={videos.length > 0 && !isTabRealTime && !isTabRealTimeV2 ? "0 0 310px" : "1 1 100%"}
      >
        <CenterFlexColumn
          gap="5px"
          align="center"
          $isBottom={videos.length > 0 || isTabRealTime || isTabRealTimeV2}
          justify="center"
        >
          <H1_FlexRow height="50px" flex="0 0 50px" gap="20px" align="center" justify="center">
            <PlaygroundTypeButton
              $selected={activeTab === PlaygroundVideoTypeTabs.AI_Video}
              size="sm"
              onClick={() => setActiveTab(PlaygroundVideoTypeTabs.AI_Video)}
              startContent={<i className="fas fa-user" />}
            >
              Speaking avatar
            </PlaygroundTypeButton>
            <PlaygroundTypeButton
              $selected={activeTab === PlaygroundVideoTypeTabs.Live_Portrait}
              size="sm"
              onClick={() => setActiveTab(PlaygroundVideoTypeTabs.Live_Portrait)}
              startContent={<i className="fas fa-person-running" />}
            >
              Image avatar
            </PlaygroundTypeButton>
            <PlaygroundTypeButton
              $selected={activeTab === PlaygroundVideoTypeTabs.Speaking_Video}
              size="sm"
              onClick={() => setActiveTab(PlaygroundVideoTypeTabs.Speaking_Video)}
              startContent={<i className="fas fa-lips" />}
            >
              Lip sync
            </PlaygroundTypeButton>
            <ConditionalRender condition={flags[FeatureFlag.dubbing]}>
              <PlaygroundTypeButton
                $selected={activeTab === PlaygroundVideoTypeTabs.Translate}
                size="sm"
                onClick={() => setActiveTab(PlaygroundVideoTypeTabs.Translate)}
                startContent={<i className="fas fa-language" />}
              >
                Dubbing
              </PlaygroundTypeButton>
            </ConditionalRender>
            <ConditionalRender condition={flags[FeatureFlag.playgroundRealTime]}>
              <PlaygroundTypeButton
                $selected={isTabRealTime}
                size="sm"
                onClick={() => setActiveTab(PlaygroundVideoTypeTabs.RealTime)}
                startContent={<i className="fas fa-user" />}
              >
                Real time (control)
              </PlaygroundTypeButton>
              <PlaygroundTypeButton
                $selected={isTabRealTimeV2}
                size="sm"
                onClick={() => setActiveTab(PlaygroundVideoTypeTabs.RealTimeV2)}
                startContent={<i className="fas fa-user" />}
              >
                Real time
              </PlaygroundTypeButton>
            </ConditionalRender>
          </H1_FlexRow>
          <ConditionalRender condition={!isTabRealTime && !isTabRealTimeV2}>
            <H1_FlexColumn position="relative" align="center" justify="center" flex="0 0 235px">
              <H1_FlexColumn align="center" justify="center" flex="1">
                <H1_FlexRow
                  align="flex-start"
                  gap="13px"
                  width={isTabLivePortrait ? "1200px" : "900px"}
                  position="relative"
                >
                  <ConditionalRender condition={isTabLivePortrait}>
                    <ContentFlexColumn>
                      <H1_FlexColumn
                        height="200px"
                        gap="18px"
                        width="calc(128px * 16 / 9)"
                        align="center"
                      >
                        <H1_FlexColumn
                          gap="14px"
                          height="128px"
                          width="calc(128px * 16 / 9)"
                          flex="0 0 128px"
                          alignSelf="flex-start"
                        >
                          <H1_TextXs color="#71717A">AVATAR IMAGE</H1_TextXs>
                          <PlaygroundSelectExistingImage
                            importLoading={importLoadingImage}
                            progress={importProgressImage}
                            executeImport={executeImportImage}
                          />
                        </H1_FlexColumn>
                      </H1_FlexColumn>
                    </ContentFlexColumn>
                    <ContentFlexColumn>
                      <H1_FlexColumn height="200px" gap="18px" width="244px" align="center">
                        <H1_FlexColumn
                          gap="14px"
                          height="128px"
                          width="244px"
                          flex="0 0 128px"
                          position="relative"
                        >
                          <H1_TextXs color="#71717A">DRIVING VIDEO</H1_TextXs>
                          <PlaygroundSelectExistingVideo
                            importLoading={importLoadingPresenter}
                            progress={importProgressPresenter}
                            executeImport={executeImportPresenter}
                            offsetX={655}
                          />
                        </H1_FlexColumn>
                      </H1_FlexColumn>
                    </ContentFlexColumn>
                    <ContentFlexColumn flex="1" gap="14px">
                      <StyledTabs
                        variant="underlined"
                        classNames={{ panel: "p-0 flex flex-col", tabList: "p-0" }}
                      >
                        <Tab
                          key={PlaygroundScriptTab.script}
                          title={<H1_TextXs color="#71717a">SCRIPT</H1_TextXs>}
                        >
                          {/* Transcript */}
                          <StyledTextArea
                            maxLength={limits.chars_per_scene}
                            onBlur={onScriptChange}
                            defaultValue={transcript}
                            value={transcript}
                            onChange={onScriptChange}
                            $error={!!validationErrors.transcript}
                          />
                        </Tab>
                        <Tab
                          key={PlaygroundScriptTab.audio}
                          title={<H1_TextXs color="#71717a">AUDIO</H1_TextXs>}
                          isDisabled
                        ></Tab>
                      </StyledTabs>
                      <PlaygroundFooterArea
                        onGenerate={onGenerate}
                        activeTab={activeTab}
                        onVoicePreviewClicked={onVoicePreviewClicked}
                        onShowVoiceModal={setShowVoiceModal}
                        voiceIdLoading={voiceIdLoading}
                        voiceIdPlaying={voiceIdPlaying}
                        generateButtonLoading={generateButtonLoading}
                        isFullTranscriptPreviewPlaying={isFullTranscriptPreviewPlaying}
                        isGenerateButtonDisabled={isGenerateButtonDisabled}
                        onPreviewText={onPreviewText}
                        charactersLength={characters.length}
                        voicesLength={voices.length}
                        transcript={transcript}
                      />
                    </ContentFlexColumn>
                  </ConditionalRender>
                  <ConditionalRender condition={isTabAiVideo}>
                    <ContentFlexColumn>
                      <ConditionalRender condition={!charactersOptions?.length || !voices.length}>
                        <CircleLoader />
                      </ConditionalRender>
                      <ConditionalRender condition={characters.length > 0 && voices.length > 0}>
                        <H1_FlexColumn gap="15px">
                          <H1_TextXs color="#71717A">AVATAR</H1_TextXs>
                          <PlaygroundPresenter />
                        </H1_FlexColumn>
                      </ConditionalRender>
                    </ContentFlexColumn>
                    <ContentFlexColumn flex="1" gap="14px">
                      <StyledTabs
                        variant="underlined"
                        classNames={{ panel: "p-0 flex flex-col", tabList: "p-0" }}
                      >
                        <Tab
                          key={PlaygroundScriptTab.script}
                          title={<H1_TextXs color="#71717a">SCRIPT</H1_TextXs>}
                        >
                          {/* Transcript */}
                          <StyledTextArea
                            maxLength={limits.chars_per_scene}
                            onBlur={onScriptChange}
                            defaultValue={transcript}
                            value={transcript}
                            onChange={onScriptChange}
                            $error={!!validationErrors.transcript}
                          />
                        </Tab>
                        <Tab
                          key={PlaygroundScriptTab.audio}
                          title={<H1_TextXs color="#71717a">AUDIO</H1_TextXs>}
                          isDisabled
                        ></Tab>
                      </StyledTabs>
                      <PlaygroundFooterArea
                        onGenerate={onGenerate}
                        activeTab={activeTab}
                        onVoicePreviewClicked={onVoicePreviewClicked}
                        onShowVoiceModal={setShowVoiceModal}
                        voiceIdLoading={voiceIdLoading}
                        voiceIdPlaying={voiceIdPlaying}
                        generateButtonLoading={generateButtonLoading}
                        isFullTranscriptPreviewPlaying={isFullTranscriptPreviewPlaying}
                        isGenerateButtonDisabled={isGenerateButtonDisabled}
                        onPreviewText={onPreviewText}
                        charactersLength={characters.length}
                        voicesLength={voices.length}
                        transcript={transcript}
                      />
                    </ContentFlexColumn>
                  </ConditionalRender>
                  <ConditionalRender condition={isTabTranslate}>
                    <ContentFlexColumn>
                      <H1_FlexColumn width="244px" flex="0 0 244px" gap="14px">
                        <ConditionalRender condition={!charactersOptions?.length || !voices.length}>
                          <CircleLoader />
                        </ConditionalRender>
                        <H1_TextXs color="#71717A">SOURCE VIDEO</H1_TextXs>
                        <PlaygroundSelectExistingVideo
                          importLoading={importLoadingPresenter}
                          progress={importProgressPresenter}
                          executeImport={executeImportPresenter}
                          isSmall
                          offsetX={635}
                        />
                      </H1_FlexColumn>
                    </ContentFlexColumn>
                    <ContentFlexColumn flex="1">
                      <PlaygroundTargetLanguage
                        text="SOURCE LANGUAGE"
                        selectedLanguage={sourceLanguage}
                        onSelect={onSelectSourceLanguage}
                        withAutoDetectOption
                      />
                      <PlaygroundTargetLanguage
                        text="TARGET LANGUAGE"
                        selectedLanguage={selectedLanguage}
                        onSelect={onSelectLanguage}
                      />
                      <PlaygroundFooterArea
                        onGenerate={onGenerate}
                        activeTab={activeTab}
                        onVoicePreviewClicked={onVoicePreviewClicked}
                        onShowVoiceModal={setShowVoiceModal}
                        voiceIdLoading={voiceIdLoading}
                        voiceIdPlaying={voiceIdPlaying}
                        generateButtonLoading={generateButtonLoading}
                        isFullTranscriptPreviewPlaying={isFullTranscriptPreviewPlaying}
                        isGenerateButtonDisabled={isGenerateButtonDisabled}
                        onPreviewText={onPreviewText}
                        charactersLength={characters.length}
                        voicesLength={voices.length}
                        transcript={transcript}
                      />
                    </ContentFlexColumn>
                  </ConditionalRender>
                  <ConditionalRender condition={isTabSpeakingVideo}>
                    <ContentFlexColumn>
                      <H1_FlexColumn width="244px" gap="14px">
                        <ConditionalRender condition={!charactersOptions?.length || !voices.length}>
                          <CircleLoader />
                        </ConditionalRender>
                        <H1_TextXs color="#71717A">SOURCE VIDEO</H1_TextXs>
                        <PlaygroundSelectExistingVideo
                          importLoading={importLoadingPresenter}
                          progress={importProgressPresenter}
                          executeImport={executeImportPresenter}
                          isSmall
                          offsetX={635}
                        />
                      </H1_FlexColumn>
                    </ContentFlexColumn>
                    <ContentFlexColumn flex="1" gap="14px">
                      <StyledTabs
                        variant="underlined"
                        classNames={{ panel: "p-0 flex flex-col", tabList: "p-0" }}
                      >
                        <Tab
                          key={PlaygroundScriptTab.script}
                          title={<H1_TextXs color="#71717a">SCRIPT</H1_TextXs>}
                        >
                          {/* Transcript */}
                          <StyledTextArea
                            maxLength={limits.chars_per_scene}
                            onBlur={onScriptChange}
                            defaultValue={transcript}
                            value={transcript}
                            onChange={onScriptChange}
                            $error={!!validationErrors.transcript}
                          />
                        </Tab>
                        <Tab
                          isDisabled
                          key={PlaygroundScriptTab.audio}
                          title={<H1_TextXs color="#71717a">AUDIO</H1_TextXs>}
                        >
                          AUDIO
                        </Tab>
                      </StyledTabs>

                      <PlaygroundFooterArea
                        onGenerate={onGenerate}
                        activeTab={activeTab}
                        onVoicePreviewClicked={onVoicePreviewClicked}
                        onShowVoiceModal={setShowVoiceModal}
                        voiceIdLoading={voiceIdLoading}
                        voiceIdPlaying={voiceIdPlaying}
                        generateButtonLoading={generateButtonLoading}
                        isFullTranscriptPreviewPlaying={isFullTranscriptPreviewPlaying}
                        isGenerateButtonDisabled={isGenerateButtonDisabled}
                        onPreviewText={onPreviewText}
                        charactersLength={characters.length}
                        voicesLength={voices.length}
                        transcript={transcript}
                      />
                    </ContentFlexColumn>
                  </ConditionalRender>
                </H1_FlexRow>
                <HiddenAudioPlayer>
                  <audio
                    autoPlay={false}
                    ref={audioPreviewRef}
                    src={previewVoice ? previewVoice.sample_url : (voicePreviewUrl as string)}
                    onCanPlay={onCanPlayPreview}
                    onEnded={onEndedAudio}
                  >
                    <track kind="captions" />
                  </audio>
                </HiddenAudioPlayer>
              </H1_FlexColumn>
              <ConditionalRender condition={!!isCustomVoiceSet && !!voiceFileAsUrl}>
                <audio
                  autoPlay={false}
                  ref={customAudioPreviewRef}
                  src={voiceFileAsUrl as string}
                  onCanPlay={onCanPlayCustomVoice}
                  onEnded={onEndedAudio}
                >
                  <track kind="captions" />
                </audio>
              </ConditionalRender>
            </H1_FlexColumn>
          </ConditionalRender>
        </CenterFlexColumn>
      </MainFlexColumn>
      <TranscriptAudioUploadPlaygroundModal
        file={voiceFile}
        open={showVoiceModal}
        closeModal={onCloseVoiceModal}
        reset={resetVoiceRecord}
        onSelectFile={onSelectFileVoice}
        importLoading={importLoadingVoice}
        executeImport={executeImport}
      />
      <HiddenFutureUseItems justify={"center"} flex={"1"}>
        <H1_FlexColumn flex={"0 0 50%"}>
          <FlexColumnFormContainer flex={"1 0 auto"} gap={"20px"}>
            <ConditionalRender condition={!pageLoading}>
              <Button variant="light" onClick={onRecordPresenter}>
                Record Presenter
              </Button>

              <ConditionalRender condition={!!isCustomPresenterSet}>
                <TitledInput gap={"5px"} title={"Gender"}>
                  <H1_FlexRow gap={"8px"}>
                    <ButtonBox
                      onClick={() => onGenderChange(Gender.male)}
                      icon={<i className="fa-solid fa-mars"></i>}
                      title={"Male"}
                      selected={selectedGender === Gender.male}
                    />
                    <ButtonBox
                      onClick={() => onGenderChange(Gender.female)}
                      icon={<i className="fa-solid fa-venus"></i>}
                      title={"Female"}
                      selected={selectedGender === Gender.female}
                    />
                  </H1_FlexRow>
                </TitledInput>
              </ConditionalRender>
            </ConditionalRender>
          </FlexColumnFormContainer>
        </H1_FlexColumn>
      </HiddenFutureUseItems>
    </BackgroundFlexColumn>
  );
};

export default PlayGroundPage;
