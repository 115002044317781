import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Divider, Select } from "antd";
import { onImageError, replaceFlagSeparator } from "app/utils/helpers";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { CustomVoice, Gender, Voice } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as VoiceSelector from "app/store/selectorsV2/voices.selectors";
import { playgroundActions } from "app/store/slices/playground.slice";
import { charactersGlobalSelectors, voicesGlobalSelectors } from "app/store/adapters/adapters";
import ConditionalRender from "app/components/common/ConditionalRender";
import { BlackButton } from "app/pages/PlayGround/BlackButton";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { Button } from "@nextui-org/react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const { Option } = Select;

const CustomVoiceBackground = styled(H1_FlexRow)`
  background: ${(props) => props.theme.blue1};
  i {
    color: ${(props) => props.theme.blue4};
  }
`;

const BlackSelect = styled(Select)`
  width: 100%;
  && .ant-select-selector {
    border-radius: 4px;
    border: none;
    height: 44px;
    width: 100%;
    background: ${({ theme }) => `${theme.blue2}1f`};
    align-items: center;
    color: white;
    .ant-select-selection-item {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
`;

const BlackSearch = styled(H1_Input)`
  div,
  input {
    background: #343443;
    color: white;
    &::placeholder,
    ::placeholder {
      color: #f5f5f5;
      font-weight: 400;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    }
  }
  i {
    background: #343443;
    color: white;
  }
`;

const WhiteArrowButton = styled(Button)<{ $hidden: boolean }>`
  min-width: 10px;
  position: absolute;
  right: 0px;
  top: calc(50% - 15px);
  color: white;
  && {
    background-color: transparent;
  }
  span {
    color: white;
  }
  z-index: ${({ $hidden }) => ($hidden ? -1 : 2)};
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
`;

const AbsoluteWhiteArrowButton = styled(WhiteArrowButton)`
  position: absolute;
  right: 11px;
  z-index: ${({ $hidden }) => ($hidden ? -1 : 5)};
`;

interface PlaygroundVoiceSelectionProps {
  onVoicePreviewClicked: (voice: Partial<Voice>) => void;
  onShowVoiceModal: (show: boolean) => void;
  importLoadingVoice?: boolean;
  voiceIdLoading: string;
  voiceIdPlaying: string;
  isExternalVideo: boolean;
  withGenderSelection: boolean;
}

const PlaygroundVoiceSelection = ({
  withGenderSelection,
  onVoicePreviewClicked,
  onShowVoiceModal,
  importLoadingVoice,
  voiceIdLoading,
  voiceIdPlaying,
  isExternalVideo
}: PlaygroundVoiceSelectionProps) => {
  const [voiceGender, setVoiceGender] = useState<Gender | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isVoiceMenuOpen, setIsVoiceMenuOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const selectedVoiceId = useAppSelector((state) => state.playground.selectedVoiceId);
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voicesGlobalSelectors.selectById(state, selectedVoiceId || "")
  );
  const selectedPresenterId = useAppSelector((state) => state.playground.selectedPresenterId);
  const customVoice = useAppSelector((state) => state.playground.customVoice);
  const characters = useAppSelector(charactersGlobalSelectors.selectAll);
  const selectedGender = useAppSelector((state) => state.playground.selectedGender);
  const isCustomVoiceSet = !!customVoice && customVoice.id === selectedVoiceId;
  const voices: Voice[] = useAppSelector((state) =>
    VoiceSelector.getFilteredVoicesBySearch(state, searchValue, "voice")
  );

  const voicesOptions: Partial<CustomVoice>[] = useMemo(() => {
    const realsCharacter = characters.find((c) => c.character_id === selectedPresenterId);
    const result: Partial<CustomVoice>[] = voices
      .filter((voice) => {
        return (selectedGender || voiceGender) && isExternalVideo
          ? voice.gender === selectedGender || voice.gender === voiceGender
          : !selectedGender && isExternalVideo
          ? true
          : selectedPresenterId && realsCharacter && realsCharacter?.gender
          ? realsCharacter.gender === voice.gender
          : true;
      })
      .map((voice) => {
        return {
          ...voice,
          image: `https://flagcdn.com/${replaceFlagSeparator(voice.local)}.svg`,
          url: ""
        };
      });

    if (
      !isExternalVideo &&
      realsCharacter &&
      realsCharacter.gender !== selectedVoice?.gender &&
      !isCustomVoiceSet &&
      result.length > 0
    ) {
      dispatch(playgroundActions.updatePlayGroundDetails({ selectedVoice: result[0].id }));
    }

    if (customVoice) {
      result.unshift(customVoice);
    }

    return result;
  }, [
    voices,
    selectedGender,
    customVoice,
    characters,
    selectedPresenterId,
    isExternalVideo,
    voiceGender
  ]);

  useEffect(() => {
    // First place may contain custom voice
    if (!selectedVoiceId && voicesOptions?.length > 1) {
      dispatch(
        playgroundActions.updatePlayGroundDetails({
          selectedVoice: voicesOptions[1].id
        })
      );
    }
  }, [voicesOptions?.length, selectedVoiceId]);

  const onSelectVoice = (value: any) => {
    dispatch(playgroundActions.updatePlayGroundDetails({ selectedVoice: value }));
    dispatch(playgroundActions.cleanPlaygroundAudio());
  };

  const onClickMaleVoice = () => {
    setVoiceGender(voiceGender === Gender.male ? undefined : Gender.male);
  };
  const onClickFemaleVoice = () => {
    setVoiceGender(voiceGender === Gender.female ? undefined : Gender.female);
  };

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(googleEvents.playgroundVoiceSearch({ value: e.target.value }));
    setSearchValue(e.target.value);
  };

  const onClickClearSearch = () => {
    setSearchValue("");
  };

  const onClickPlay = (e: React.MouseEvent, clickedVoice: Partial<Voice>) => {
    e.stopPropagation();
    dispatch(googleEvents.playgroundPlayVoicePreview({ voiceId: clickedVoice.id as string }));
    onVoicePreviewClicked(clickedVoice);
  };

  const onClickUseAudio = () => {
    dispatch(googleEvents.playgroundVoicesUseAudio());
    onShowVoiceModal(true);
  };

  return (
    <H1_FlexRow position="relative" width="270px">
      <AbsoluteWhiteArrowButton
        $hidden={isCustomVoiceSet}
        onClick={(e) => onClickPlay(e, selectedVoice as Voice)}
        startContent={
          <i
            className={
              "voice-white-arrow-play " +
              (voiceIdLoading === selectedVoiceId
                ? "fas fa-spinner-third fa-spin"
                : voiceIdPlaying === selectedVoiceId
                ? "fas fa-pause"
                : "fas fa-play")
            }
          />
        }
        isIconOnly
        variant="light"
        size="sm"
      />
      <BlackSelect
        dropdownMatchSelectWidth={417}
        placeholder="Select a voice"
        optionFilterProp="children"
        onSelect={onSelectVoice}
        value={selectedVoiceId}
        popupClassName="playground-black-select-dropdown"
        suffixIcon={null}
        dropdownRender={(menu) => (
          <>
            <H1_FlexColumn>
              <H1_FlexRow gap="14px" align="center">
                <BlackSearch
                  isFixedWidth
                  allowClear
                  onChange={onSearchChanged}
                  onClear={onClickClearSearch}
                  initialValue={searchValue}
                  minWidth="279px"
                  placeholder="Search Voice..."
                />
                <BlackButton loading={importLoadingVoice} onClick={onClickUseAudio}>
                  Use Audio
                </BlackButton>
              </H1_FlexRow>
              <Divider />
              <ConditionalRender condition={withGenderSelection}>
                <H1_FlexRow margin="0 0 10px 0" gap="14px" align="center">
                  <Button
                    color={voiceGender === Gender.male ? "primary" : "default"}
                    onClick={onClickMaleVoice}
                    size="sm"
                    startContent={<i className="far fa-mars" />}
                  >
                    Male
                  </Button>
                  <Button
                    color={voiceGender === Gender.female ? "primary" : "default"}
                    onClick={onClickFemaleVoice}
                    size="sm"
                    startContent={<i className="far fa-venus" />}
                  >
                    Female
                  </Button>
                </H1_FlexRow>
              </ConditionalRender>
            </H1_FlexColumn>
            {menu}
          </>
        )}
      >
        {voicesOptions.map((voice) => (
          <Option key={voice.id} value={voice.id}>
            <H1_FlexRow
              justify="space-between"
              width="100%"
              align="center"
              onClick={() => setIsVoiceMenuOpen(!isVoiceMenuOpen)}
            >
              <H1_FlexRow align="center">
                <ConditionalRender condition={!!voice.url}>
                  <CustomVoiceBackground
                    margin="0 8px 0 0"
                    width="30px"
                    height="16px"
                    align="center"
                    justify="center"
                  >
                    <i className="fa-solid fa-microphone" />
                  </CustomVoiceBackground>
                </ConditionalRender>
                <ConditionalRender condition={!voice.url}>
                  <img
                    onError={onImageError}
                    src={voice.image}
                    alt={voice.display_name}
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                </ConditionalRender>
                {voice.display_name}
              </H1_FlexRow>
              <WhiteArrowButton
                $hidden={!!voice.url}
                onClick={(e) => onClickPlay(e, voice)}
                startContent={
                  <i
                    className={
                      "voice-white-arrow-play " +
                      (voiceIdLoading === voice.id
                        ? "fas fa-spinner-third fa-spin"
                        : voiceIdPlaying === voice.id
                        ? "fas fa-pause"
                        : "fas fa-play")
                    }
                  />
                }
                isIconOnly
                variant="light"
                size="sm"
              />
            </H1_FlexRow>
          </Option>
        ))}
      </BlackSelect>
    </H1_FlexRow>
  );
};

export default PlaygroundVoiceSelection;
