const normalizeEnv = () => {
  const normalizedArr = {} as Record<string, any>;
  Object.keys(import.meta.env).forEach((key) => {
    let normalized = key.replace("REACT_APP_", "").replace("AUTH0_", "").toLowerCase();
    normalized = normalized.replace(/(^|_)./g, (s) => s.slice(-1).toUpperCase());
    normalized = normalized.charAt(0).toLowerCase() + normalized.slice(1);
    normalizedArr[normalized] = import.meta.env[key];
  });
  return normalizedArr;
};

const {
  domain,
  gtmAuth,
  gtmPreview,
  clientId,
  apiUrl,
  cdnBaseDomain,
  signUrl,
  serverUrl,
  clientUrl,
  stripe,
  socketUrl,
  env,
  filestackApi,
  gtmId,
  frontoHost,
  datalessTemplate,
  unsplashClient,
  pexels,
  gapiKey,
  ogPlayerUrl,
  sentryDsn,
  disableUnsplash,
  pusherApiCluster,
  pusherApiKey,
  authRedirect,
  segmentWriteKey,
  liveBlocks,
  fbDomainVerification,
  release,
  muxEnvKey,
  surveyOnboardingId,
  cancelFormId,
  presentationWorkflowId,
  noticeable,
  defaultRecipeIdSummary,
  novuPublicKeyIdentifier,
  novuSocketUrl,
  novuBackendUrl,
  liveKitUrl,
  liveMode
} = normalizeEnv();

const responseType = "token id_token";
const scope = "openid email profile";
const logoAssetsPrefix = "https://hourone-talking-headless.s3.amazonaws.com/logo-assets";
const desktopUrl = `https://app.hourone.ai`;
const mobileAppStoreUrl = "https://apps.apple.com/il/app/pocket-capture/id1628282804";

const logoutRedirectTo = `${clientUrl || window.location.origin}`;
const callbackUrl = `${clientUrl || window.location.origin}`;
const blueSnapBase =
  env !== "production" && env !== "staging"
    ? "https://sandbox.bluesnap.com"
    : "https://ws.bluesnap.com";

const launchDarkly = {
  production: "6261074f543a351510c33bf3",
  development: "6261074f543a351510c33bf2",
  test: ""
};

//
const Config = {
  auth0: {
    domain,
    clientId,
    callbackUrl,
    apiUrl,
    responseType,
    scope,
    logoutRedirectTo
  },
  fbDomainVerification,
  filestackApi,
  signUrl,
  cdnBaseDomain,
  serverUrl,
  clientUrl,
  socketUrl,
  env,
  logoAssetsPrefix,
  gtmId,
  gtmAuth,
  gtmPreview,
  frontoHost,
  datalessTemplate,
  unsplashClient,
  pexels,
  gapiKey,
  blueSnapBase,
  launchDarkly,
  stripe,
  sentryDsn,
  disableUnsplash,
  pusherApiCluster,
  pusherApiKey,
  authRedirect,
  desktopUrl,
  ogPlayerUrl,
  mobileAppStoreUrl,
  segmentWriteKey,
  liveBlocks,
  release,
  muxEnvKey,
  surveyOnboardingId,
  cancelFormId,
  presentationWorkflowId,
  noticeable,
  defaultRecipeIdSummary,
  novuPublicKeyIdentifier,
  novuSocketUrl,
  novuBackendUrl,
  liveKitUrl,
  liveMode
};

export default Config;
